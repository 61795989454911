import client, { clientWithCredentials } from './client'
import fragments, {
  invoiceFields,
  mealData,
  mealDataComplete
} from './gqlFragments'
import { getImpersonator, getTokenSilently } from '../auth0'
import {
  getCrossSellingItems,
  getMealMenuServiceQuery,
  getMealNutritionInfoLite,
  getMealsLite,
  getMenuCollectionsQuery,
  getMerchandiseMealsQuery,
  getRecipeQuery,
  menuServiceQuery,
  subscriptionBackQuery
} from './getMenuGraphQLQueries'

import { applyHooks } from './hooks'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import gqlClient from './gqlClient'
import menuService from './menuService'
import { getOrigin } from './get-origin'

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

const auth0Client = async (path, body) => {
  const URL = `${process.env.REACT_APP_SUBSCRIPTION_API_URL}/auth0/`
  const impersonator = getImpersonator()

  if (impersonator) {
    return axios.post(`${URL}${path}`, body, {
      headers: {
        'Content-type': 'application/json',
        'x-cook-impersonation-secret': impersonator.secret,
        'x-cook-impersonation-email': impersonator.email
      }
    })
  }

  const token = await getTokenSilently()
  return axios.post(`${URL}${path}`, body, {
    headers: {
      Authorization: token ? `${token}` : '',
      'Content-type': 'application/json'
    }
  })
}

const applyFilter = call => call.then(res => applyHooks(res))

// Recovery Password
/**
 * @param {string} email user email
 */
export const recoverPassword = ({ email }) =>
  applyFilter(client(`user/recover`, 'post', { email }))

// LOG IN
/**
 * @param {string} email user email
 * @param {string} password user password
 */
export const login = ({ email, password }) =>
  applyFilter(
    clientWithCredentials(
      `login`,
      'post',
      { username: email, password },
      process.env.REACT_APP_SUBSCRIPTION_API_URL
    )
  )

// CHECK AUTH

export const checkAuth = () =>
  clientWithCredentials(
    `check-auth`,
    'get',
    null,
    process.env.REACT_APP_SUBSCRIPTION_API_URL
  )

// LOG OUT

export const logout = () =>
  clientWithCredentials(
    `logout`,
    'get',
    null,
    process.env.REACT_APP_SUBSCRIPTION_API_URL
  )

export const getKustomerToken = async () => {
  const auth0Token = await getTokenSilently()
  const response = await axios({
    headers: {
      Authorization: auth0Token ? `${auth0Token}` : ''
    },
    method: 'get',
    url: `${process.env.REACT_APP_SUBSCRIPTION_API_URL}/kustomer/jwt-token`
  })
  return response.data.jwtToken
}

// USER DATA
export const getUser = () =>
  gqlClient.query(`
    query {
      user {
        firstname
        lastname
        email
        gender
        birthdate
        about_me
        favorite_food
        phone
        street
        floor
        referral_code
        zipcode
      }
    }
  `)

/**
 * Update first name, last name and phone
 * @param {string} firstName
 * @param {string} lastName
 * * @param {string} phone
 */
export const updateUserInfo = data =>
  auth0Client('account/updateuser', data).then(res => res.data)

export const changePassword = data =>
  auth0Client('account/changepassword', data).then(res => res.data)

/**
 * Updates user's personal info.
 * @param {Object} data
 * @param {string} data.firstname
 * @param {string} data.lastname
 * @param {string} data.phone
 * @param {string} [data.currentPassword]
 * @param {string} [data.newPassword]
 * @param {string} [data.confirmPassword]
 * @returns {Promise<Object>}
 */
export const updatePersonalInfo = data =>
  auth0Client('account/updatepersonalinfo', data).then(res => res.data)
/**
 * get meal menu by several filters as date, zipcode, etc
 * @param {Object} filter
 * @param {boolean} filter.lunch
 * @param {Object} filter.date
 * @param {Date} filter.date.start
 * @param {Date} filter.date.end
 * @param {Object} filter.van
 * @param {String} filter.van.zipcode
 */
export const getMenuByFilters = filter =>
  applyFilter(client(`meals/filter/`, 'post', filter))

// SUBCRIPTION
/**
 * Get subcription
 */
export const getSubscription = () =>
  gqlClient.query(`
    query subscription {
      users {
        ...UserData
        profiles {
         ...Profile
        }
        availableDeliveryDays {
          day
          label
          value
        }
        addresses {
            id
            isActive
            city
            country
            region
            regionId
            postcode
            telephone
            street
            instruction
        }
        expireDate
        planChanges {
          id
          plan {
            id
            segmentId
          }
          expireDate
        }
      }
      plans (order: "price"){
        id
        title
        sidedishes
        price
        deliveryFee
        discountPrice
        mealsPerDelivery
        deliveries
        productDiscountRate
        planGiftId
        segmentId
        deliveryType {
          id
          name
        }
        available
        featured
        type
        segment {
          allowPlanChange
          allowExtraMeals
          isBoxPlan
        }
      }
      lastOrderForReview {
        id
        order_creation_id
        delivery_date
        time_start
        time_end
        items {
          product {
            id
            name
            sku
            assortment
            short_description
            image
            calories
            category_id
            sidedish
            chef_firstname
            chef_lastname
            logopic
            bannerpic
            tags
          }
        }
      }
      settings {
        key
        value
      }
      stores {
        id
        name
      }
    }
    ${fragments.userData}
    ${fragments.profile}
  `)

export const getUserSettings = () =>
  gqlClient.query(`
    query {
      settings {
          key
          value
      }
    }
  `)

export const getProfiles = () =>
  gqlClient.query(`
    query {
      users {
        name
        profiles {
         ...Profile
        }
      }
    }
    ${fragments.profile}
  `)

export const getUpcoming = () =>
  gqlClient.query(`
    query upcomingDays {
      upcomingDays {
        id
        address {
          id
          city
          region
          street
          postcode
        }
        holidayMessage
        available
        menuAvailable
        menuAvailableFrom
        canEdit
        date
        displayDate
        skip
        isFirstOrder
        isPaused
        rescheduled
        scheduled
        cutoff {
          time
          userTimeZone
          utcOffset
        }
        cart {
          product {
              id
              inventoryId
              name
              sku
              short_description
              image_path
              image
              calories
              category_id
              chef_firstname
              chef_lastname
              is_celebrity_chef
              chef_id
              logopic
              bannerpic
              meat_type
              premium_special
              items {
                product {
                  id
                  inventoryId
                  name
                  sku
                  short_description
                  image_path
                  image
                  calories
                  category_id
                  sidedish
                  chef_firstname
                  chef_lastname
                  is_celebrity_chef
                  chef_id
                  logopic
                  bannerpic
                  tags
                  meat_type
                  premium_special
                  price_incl_tax
                  realPrice
                }
                qty
              }
          },
          qty
        }
        recommendation {
          id
          date
          rescheduled
          meals {
            id
            inventoryId
            batch_id
            name
            short_description
            image {
              full_path
            }
            calories
            chef_firstname
            chef_lastname
            is_celebrity_chef
            chef_id
            meat_type
            logopic
            bannerpic
            qty
            premium_special
            category_id: category
          }
          products {
            id
            inventoryId
          }
        }
        order {
          id
          origin
          allowEditAfterCutoff
          delivery_date
          address {
            id
            city
            country
            region
            regionId
            postcode
            telephone
            street
          }
          time_start
          time_end
          items {
            product {
              id
              inventoryId
              name
              sku
              short_description
              image_path
              image
              calories
              category_id
              sidedish
              chef_firstname
              chef_lastname
              chef_bannerpic
              is_celebrity_chef
              chef_id
              logopic
              bannerpic
              tags
              meat_type
              premium_special
              items {
                product {
                  id
                  inventoryId
                  name
                  sku
                  short_description
                  image_path
                  image
                  calories
                  category_id
                  sidedish
                  chef_firstname
                  chef_lastname
                  is_celebrity_chef
                  chef_id
                  logopic
                  bannerpic
                  tags
                  meat_type
                  premium_special
                }
                price {
                  price
                  originalPrice
                }
                qty
              }
            }
            price {
              price
              originalPrice
            }
            qty
          }
          tip
          grandTotal
          orderStatus {
            state
            status
          }
        }
      }
    }
  `)

/**
 * Create an order.
 * @param {string} payload.date date in format YYYY/MM/DD
 * @param {string} payload.start time in format HH:mm
 * @param {string} payload.end time in format HH:mm
 * @param {string} payload.dorman define
 * @param {string} payload.special special
 */
export const createOrder = payload =>
  gqlClient.mutation(
    `
    mutation createOrder($order: CreateOrderInput!, $origin: OperationOrigin) {
      createOrder(order: $order, origin: $origin) {
        __typename
        ... on OrderCreation{
            id
        },
        ... on OrderCreationError {
            error,
            outOfStockIds
        }
      }
    }
  `,
    {
      order: payload,
      origin: getOrigin()
    }
  )

export const changeSubscriptionPreferences = payload =>
  gqlClient.mutation(
    `
  mutation changeUserProfile ($userProfile: ChangeUserProfileInput!){
      changeUserProfile(userProfile: $userProfile){
        ... on UserProfile {
          ...Profile
        }
        ... on userProfileChangeErrorType {
            error
        }
      }
  }
  ${fragments.profile}
  `,
    {
      userProfile: payload
    }
  )

export const removeProfile = profile_id => {
  return gqlClient.mutation(
    `
    mutation removeUserProfile($profile_id: Int!) {
      removeUserProfile(profile_id: $profile_id) {
        ... on UserProfileDeleted {
          profiles {
            ...Profile
            __typename
          }
        }
        ... on userProfileChangeErrorType {
          error
          __typename
        }
        __typename
      }
    }
    ${fragments.profile}
    `,
    {
      profile_id
    }
  )
}

/**
 * Update an order.
 * @param {string} payload.id_order order id that is being updated
 * @param {string} payload.date date in format YYYY/MM/DD
 * @param {string} payload.start time in format HH:mm
 * @param {string} payload.end time in format HH:mm
 * @param {string} payload.dorman define
 * @param {string} payload.special special
 */
export const updateOrder = payload =>
  gqlClient.mutation(
    `
  mutation updateOrder(
    $order: UpdateOrderInput!
    $origin: OperationOrigin
    $reason: Int
  ) {
    updateOrder(order: $order, origin: $origin, reason: $reason) {
      __typename
      ... on OrderCreation {
        id
    },
    ... on OrderCreationError {
        error,
        outOfStockIds
    }
    }
  }
`,
    {
      order: payload,
      origin: getOrigin()
    }
  )

/**
 * Share an order.
 * @param  {string} payload.order_id order_id
 * @param  {string} payload.friendName firstName
 * @param  {string} payload.friendLastname lastName
 * @param  {string} payload.friendEmail email
 * @param  {string} payload.friendZipCode zip
 * @param  {string} payload.friendPhone phone
 * @param  {string} payload.friendAddress address
 * @param  {string} payload.friendFloor floor
 */
export const shareOrder = payload =>
  applyFilter(client(`subscription/shareorder`, 'post', payload))

/**
 * Reschedule an order.
 * @param  {string} payload.order_id order_id - order id that is being updated
 * @param  {string} payload.timeslot timeslot - time in format HH:mm-HH:mm ex: "09:00-10:00"
 * @param  {string} payload.date date - time in format HH:mm-HH:mm ex: "09:00-10:00"
 */
export const rescheduleOrder = payload =>
  gqlClient.mutation(
    `
  mutation rescheduleOrder($reschedule: CreateRescheduleInput!, $origin: OperationOrigin) {
    createReschedule(reschedule: $reschedule, origin: $origin) {
      __typename
      ... on Reschedule {
          id
      },
      ... on OrderCreationError {
          error
      }
    }
  }
`,
    {
      reschedule: payload,
      origin: getOrigin()
    }
  )

/**
 * Skip/Unskip order.
 */
export const unskip = payload =>
  gqlClient.mutation(
    `
mutation createUnskip($unskip: SkipInput!, $origin: OperationOrigin) {
  createUnskip(unskip: $unskip, origin: $origin) {
    __typename
    ... on Skip {
        id
    },
    ... on OrderCreationError {
        error
    }
  }
}
`,
    {
      unskip: payload,
      origin: getOrigin()
    }
  )

// GetMenu
/**
 * Get Menu for today.
 * @param {string} date date in format YYYY/MM/DD
 * @param useMenuService defines if menu should be retrieved from Menu Service
 */
export const getUserMenu = ({ date, filters }, useMenuService) =>
  menuService.query(
    useMenuService ? menuServiceQuery : subscriptionBackQuery,
    {
      date,
      filters
    },
    useMenuService
  )

/**
 * Get Menu Collections.
 * @param {string} date date in format YYYY/MM/DD
 */

export const getMenuCollectionsWithMeals = (date, useMenuService) =>
  menuService.query(
    getMenuCollectionsQuery,
    {
      date
    },
    useMenuService
  )

/**
 * Get Menu Collections for Orders Page.
 * @param {string} date date in format YYYY/MM/DD
 */

export const getOrdersPageCollections = (date, useMenuService) =>
  menuService.query(
    getMerchandiseMealsQuery,
    {
      date
    },
    useMenuService
  )

/**
 * Get Stock
 * @param {string} date date in format YYYY/MM/DD
 */
export const getStock = ({ date }) =>
  gqlClient.query(
    `
  query getPersonalizedMenu($date: String!) {
    personalized(date: $date) {
      categories {
        personalized {
          meals {
            entity_id
            batch_id
            stock
          }
        }
        meals {
          entity_id
          batch_id
          stock
        }
      }
    }
  }
`,
    {
      date
    }
  )

// GetMenu
export const getMenu = ({ date, zipcode }) => {
  return gqlClient.publicQuery(
    `
    query getMenu($date: String!, $zipcode: String!) {
      menu(date: $date, zipcode: $zipcode) {
        available
        categories {
          id
          title
          label
          subtitle
          meals {
            entity_id
            batch_id
            name
            short_description
            calories
            image
            image_path
            price
            sku
            entity_id
            category_id
            chef_id
            chef_firstname
            chef_lastname
            feature {
              name
              description
              icon
              color
              background
            }
            logopic
            bannerpic
            stock
            inStock
            meat_type
            stars
            reviews
            sidedish {
              id
              __typename
            }
            specifications_detail {
              label
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `,
    { date, zipcode }
  )
}

// ValidateZipcode
export const validateZipcode = ({ zipcode }) => {
  return gqlClient.publicQuery(
    `
      query validateZipcode($zipcode: String!) {
        zipcodeValidation(zipcode: $zipcode)
      }
    `,
    { zipcode }
  )
}

// GetTimeSlots
/**
 * Get timeslots.
 * @param {string} zipcode
 */

export const getTimeSlots = ({ zipcode }) =>
  applyFilter(client(`quote/allslots/${zipcode}`, 'get'))

export const getMealDetail = ({ meal_id }) =>
  menuService.query(
    getMealMenuServiceQuery,
    {
      id: Number(meal_id)
    },
    true
  )

/**
 * Get Meal Data.
 * @param {string} data.chef_id
 */
export const getChefDetail = ({ chef_id }) =>
  applyFilter(client(`chefs/one/${chef_id}`, 'get'))

// getChefDetail
/**
 * Get Meal Data.
 * @param {string} data.meal_id
 */
export const getChefReviews = ({ chef_id }) =>
  applyFilter(client(`chefs/reviews/${chef_id}`, 'get'))

/**
 * Get Meal Reviews by meal_id.
 * @param {string} data.meal_id
 */
export const getMealReviews = ({ meal_id }) =>
  applyFilter(client(`meals/reviews/${meal_id}/50`, 'get'))

/**
 * set Meal Reviews by past orders.
 * @param {Object} review
 */
export const setMealReviewForOrder = (productId, orderId, review) => {
  return gqlClient.mutation(
    `
    mutation createUserReview($order: Int!, $rating: Int!, $review: String!, $product: String!, $reasons: [String]) {
      createUserReview(order: $order, rating: $rating, review: $review, product: $product, reasons: $reasons) {
        __typename
        ... on GenericError {
          error
        }
        ... on GenericResponse {
          result
        }
      }
    }
  `,
    {
      product: productId,
      order: +orderId,
      rating: review.rating,
      review: review.review,
      reasons: review.reasons || []
    }
  )
}

export const editUserReview = review =>
  gqlClient.mutation(
    `
  mutation editUserReview($id: Int!, $rating: Int!, $review: String! $reasons: [String])  {
    editUserReview(id: $id, rating: $rating, review: $review, reasons: $reasons) {
       __typename
       ... on GenericError {
         error
       }
       ... on GenericResponse {
         result
       }
   }
}
`,
    {
      id: review.id,
      rating: review.rating,
      review: review.review,
      reasons: review.reasons || []
    }
  )

/**
 * Get current user addresses
 */
export const getUserAddresses = () =>
  gqlClient.query(`
    query {
      users {
        ring_id
        addresses {
          id
          isActive
          city
          country
          region
          regionId
          postcode
          telephone
          street
          instruction
        }
      }
    }
  `)

/**
 * create update address customer_id.
 * @param {string} data.address_id
 * @param {string} data.zipcode
 * @param {string} data.street
 * @param {string} data.floor
 * @param {string} data.phone
 */
export const updateUserAddress = address =>
  gqlClient.mutation(
    `
    mutation changeUserAddress($address: ChangeUserAddressInput!){
      changeUserAddress(addressInfo: $address) {
        __typename
        ... on userAddressChangeErrorType {
          error
        }
        ... on AddressType {
          id
        }
      }
    }
  `,
    { address }
  )

/**
 * Soon to be deprecated - use useDeleteUserAddress hook instead.
 * @param {string} data.address_id
 */
export const deleteUserAddress = address_id =>
  gqlClient.mutation(
    `
  mutation removeUserAddress($address_id: String!){
    removeUserAddress(address_id: $address_id) {
      __typename
      ... on userAddressesErrorType {
        error
      }
      ... on userAddressesListType {
        addresses {
          id
        }
      }
    }
  }
`,
    { address_id }
  )

/**
 * change subscription days
 * @param {string} data.subscription
 * @param {string} data.days
 * @param {string} data.slots (Timeslot)
 */
export const updateSubscriptionDays = ({ days, start, end }) =>
  gqlClient.mutation(`
    mutation changeDeliveryDaysType {
      changeDeliveryDays(deliveryDays: {
        days: ${JSON.stringify(days)}
        start: "${start}"
        end: "${end}"
      }) {
          ... on User {
            ...UserData
          }
          ... on deliveryDaysChangeError {
              error
          }
      }
    }
    ${fragments.userData}
  `)

/**
 * change delivery options
 * @param {string} deliveryOptions
 */
export const updateDeliveryOptions = deliveryOptions =>
  gqlClient.mutation(`
  mutation changeDeliveryOptionsType {
    changeDeliveryOptions(deliveryOptions: "${deliveryOptions}") {
      __typename
      ... on GenericError {
        error
      }
      ... on GenericResponse {
        result
      }
    }
  }
`)

export const getInvoices = date =>
  gqlClient
    .query(
      `
        query getInvoices($date: InvoicesInput!, $liteVersion: Boolean!){
          invoices(date: $date, liteVersion: $liteVersion) {
            ${invoiceFields}
          }
        }
      `,
      {
        date,
        liteVersion: true
      }
    )
    .then(r => r.data.invoices)
    .catch(r => r.data.error)

export const getLastInvoices = () =>
  gqlClient
    .query(
      `
        query getInvoices($date: InvoicesInput, $liteVersion: Boolean!){
          invoices(date: $date, limit: 6, order: "date", liteVersion: $liteVersion) {
            ${invoiceFields}
          }
        }
    `,
      {
        liteVersion: true
      }
    )
    .then(r => r.data.invoices)
    .catch(r => r.data.error)

export const getPastOrders = ({ from, to }) =>
  gqlClient
    .query(
      `
  query getInvoices($date: InvoicesInput!, $liteVersion: Boolean!){
    invoices(date: $date, liteVersion: $liteVersion) {
      orders {
        delivery_date
        display_date
        items {
          product {
            id
            name
            sku
            short_description
            image_path
            image
            calories
            fat
            fiber
            protein
            carbs
            ingredients {
              id
              name
              componentCode
              image
              description
            }
            category_id
            category
            sidedish
            chef_firstname
            chef_lastname
            logopic
            bannerpic
            tags
            meat_type
            chef_id
            stars
            user_rating
          }
          qty
        }
      }
    }
  }
    `,
      {
        date: {
          from,
          to
        },
        liteVersion: true
      }
    )
    .then(r => r.data.invoices)
    .catch(r => r.data.error)

export const getPreferencesData = () =>
  gqlClient.query(`
    query meatTypes {
      meatTypes {
        id
        name
        types
      }
      tastes {
        id
        name
      }
      dietaryPreferences {
        id
        name
        description
      }
      diets {
        id
        name
        label
        description
        image
      }
      restrictions {
        id
        name
      }
      goals {
        id
        name
      }
      cuisines {
        id
        name
        label
      }
    }
  `)

/**
 * Set Default Address
 * @param {Number} address_id
 */
export const setDefaultAddress = address_id =>
  gqlClient.mutation(
    `
    mutation setDefaultUserAddress($address_id: String!){
      setDefaultUserAddress(address_id: $address_id) {
        __typename
        ... on userAddressesErrorType {
          error
        }
        ... on userAddressesListType {
          addresses {
            id
          }
        }
      }
    }
  `,
    { address_id }
  )

/**
 * Creates a chef message
 * payload is formdata type
 * @param {Number} chef
 * @param {String} comment
 * @param {Number} product
 */
export const chefDropline = payload =>
  applyFilter(client(`chefs/dropline`, 'post', payload))

export const getZipcodesByDeliveryZone = () =>
  applyFilter(client(`misc/zipcodesByDeliveryZone`, 'get'))

export const getPublicData = () =>
  gqlClient.publicQuery(`
    query {
      tastes {
          id
          name
      }
      dietaryPreferences {
          id
          name
          description
      }
      diets {
            id
            name
            description
            image
      }
      restrictions {
        id
        name
      }
    }
  `)

export const changeTip = tip =>
  gqlClient.mutation(
    `
    mutation changeTip($tip: Float) {
      changeUserTip(tip: $tip) {
      	... on User {
          name
        }
        ... on userInfoChangeErrorType {
          error
        }
          }
        }
  `,
    {
      tip
    }
  )

/**
 * Get available delivery days by zipcode.
 * @param {string} zipcode
 */
export const getAvailableDeliveryDaysByZipcode = ({ zipcode }) =>
  gqlClient.query(`
    query {
      deliveryDays (zipcode: "${zipcode}") {
        deliveryDays
      }
    }
`)

export const sendEmailReferral = ({ email, message, retry, meals = '' }) =>
  gqlClient.mutation(
    `
    mutation sendEmailReferral($email: String!, $message: String!, $retry: Boolean!, $meals: String){
      sendEmailReferral(email: $email, message: $message, retry: $retry, meals: $meals) {
        __typename
        ... on GenericError {
          error
        }
        ... on GenericResponse {
          result
        }
      }
    }
  `,
    { email, message, retry, meals }
  )

export const getUnsubscribeReasons = () =>
  gqlClient.query(`
    query {
      subscriptionEventReasons(event: "unsubscribe") {
        id
        text
        type
        parent
        event
        aditionalText
      }
    }
  `)

export const get25Off = () =>
  gqlClient.mutation(
    `
    mutation consumeCoupon($coupon: String!, $isResurrected: Boolean!) {
      consumeCoupon(coupon: $coupon, isResurrected: $isResurrected) {
        __typename
        ... on GenericResponse {
          result
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      coupon: 'UNSUBSGET25OFF'
    }
  )

export const createSubscriptionEvent = ({ eventType, date, reason }) =>
  gqlClient.mutation(
    `
    mutation createSubscriptionEvent($date: Date!, $reasons: [SubscriptionEventReasonInput]) {
      publicCreateSubscriptionEvent(input: {
        event: ${eventType},
        date: $date,
        reasons: $reasons
      }){
        ... on CreateSubscriptionEventErrorType {
          error
        }
        ... on CreateSubscriptionEventSuccesType {
          id
          userId
          event
          date
        }
      }
    }
    `,
    {
      date,
      reasons: reason ? [reason] : null
    }
  )

/**
 * Creates a OrderShownForReview
 * @param id order creation id
 */
export const setOrderShownForReview = id =>
  gqlClient.mutation(`
   mutation {
     setOrderShownForReview(id: ${id}) {
       ... on OrderShownForReview {
         id,
         orderCreationId
       }
       ... on OrderCreationError {
         error
       }
     }
   }
 `)

export const clearNotification = key =>
  gqlClient.mutation(
    `
    mutation clearNotification($key: String!) {
      setSetting(key: $key, value: "0") {
          ... on SetSetting {
              key
              value
          }
          ... on GenericError {
              error
          }
      }
    }
  `,
    {
      key
    }
  )

export const setSetting = ({ key, value }) =>
  gqlClient.mutation(
    `
  mutation setSetting($key: String!, $value: String!) {
    setSetting(key: $key, value: $value) {
        ... on SetSetting {
            key
            value
        }
        ... on GenericError {
            error
        }
    }
  }
`,
    {
      key,
      value
    }
  )

export const sendNutritionMessage = ({
  height,
  weight,
  allergies,
  diseases,
  message
}) =>
  gqlClient.mutation(
    `
  mutation sendNutritionMessage($height: String!, $weight: String!, $allergies: String, $diseases: String, $message: String!) {
    sendNutritionMessage(height: $height, weight: $weight, allergies: $allergies, diseases: $diseases, message: $message) {
        ... on KlaviyoEvent {
            id
        }
        ... on GenericError {
            error
        }
    }
  }
`,
    {
      height,
      weight,
      allergies,
      diseases,
      message
    }
  )

export const getTrackingInfo = orderId =>
  gqlClient.query(
    `
    query trackOrder($orderId: Int) {
      trackOrder(orderId: $orderId) {
        address
        type
        carrier
        tracking_url
        status
        updated_at
      }
    }
  `,
    {
      orderId: +orderId
    }
  )

export const addMealWish = (meal, profile) =>
  gqlClient.mutation(
    `
    mutation createMealWish($profile: Int!, $meal: Int!){
      createMealWish(mealWish: {profile: $profile, meal: $meal}) {
        ... on MealWishesType {
          mealWishes {
            id
            mealId
            mealData: ${mealDataComplete}
          }
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      meal,
      profile
    }
  )

export const removeMealWish = (meal, profile) =>
  gqlClient.mutation(
    `
    mutation removeMealWish($profile: Int!, $meal: Int!) {
      removeMealWish(mealWish: {profile: $profile, meal: $meal}) {
        ... on MealWishesType {
          mealWishes {
            id
            mealId
            ${mealDataComplete}
          }
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      meal,
      profile
    }
  )

export const addMealAvoid = (meal, profile) =>
  gqlClient.mutation(
    `
    mutation createMealAvoid($profile: Int!, $meal: Int!){
      createMealAvoid(mealAvoid: {profile: $profile, meal: $meal}) {
        ... on MealAvoidsType {
          mealAvoids {
            id
            mealId
            ${mealDataComplete}
          }
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      meal,
      profile
    }
  )

export const removeMealAvoid = (meal, profile) =>
  gqlClient.mutation(
    `
    mutation removeMealAvoid($profile: Int!, $meal: Int!) {
      removeMealAvoid(mealAvoid: {profile: $profile, meal: $meal}) {
        ... on MealAvoidsType {
          mealAvoids {
            id
            mealId
            ${mealData}
          }
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      meal,
      profile
    }
  )

export const getAvailableMeals = payload =>
  gqlClient.query(
    `
    query getAvailableMeals($userProfile: ChangeUserProfileInput!) {
      availableMeals(userProfile: $userProfile) {
        day {
          date
          total
          available
          unavailable
          meals {
            entity_id
            batch_id
            name
            short_description
            chef_lastname
            chef_firstname
            logopic
            bannerpic
            image
            image_path
            user_rating
            warnings {
              message
            }
          }
        }
      }
    }
  `,
    {
      userProfile: payload
    }
  )

export const addMealWishRequest = (text, profile) =>
  gqlClient.mutation(
    `
    mutation createMealWishRequest($profile: Int!, $text: String!){
      createMealWishRequest(mealWishRequest: {profile: $profile, text: $text}) {
        ... on MealWishRequestsType {
          mealWishRequests {
            profileId
            text
          }
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      text,
      profile
    }
  )

export const sendSatisfactionResponse = ({
  experience,
  issueId,
  issue,
  comment,
  experiment
}) =>
  gqlClient.mutation(
    `
    mutation createSatisfactionQuestion($experience: Boolean!, $issueId: Int, $issue: String!, $comment: String!, $experiment: String ){
      createSatisfactionQuestion(satisfactionQuestion: {experience: $experience, issueId: $issueId, issue: $issue, comment: $comment, experiment: $experiment}) {
        ... on SatisfactionQuestionsType {
          satisfactionQuestions {
            experience
            issue_id
            issue
            comment
            experiment
          }
        }
        ... on GenericError {
          error
        }
      }
    }
  `,
    {
      experience,
      issueId,
      issue,
      comment,
      experiment
    }
  )

export const getSortingTypes = () =>
  gqlClient.query(
    `
      query getUserLastReview {
        sortingTypes {
              name
              order {
                  label
                  sort {
                      label
                      order
                  }
              }
          }
      }
    `
  )

/**
 * Get chef data by id
 * @param {string} chef_id
 */
export const getChefById = ({ chef_id }) =>
  gqlClient.publicQuery(
    `
    query getChef($id: Int!) {
      chef(id: $id){
        entity_id
        mageuserid
        firstname
        lastname
        complocality
        stars
        reviews
        compdesi
        image_url
        logopic
        logopic_url
        bannerpic
        countries {
          key
          label
        }
        is_celebrity_chef
        instagram_id
        instagram_user
        pinterest_id
        youtube_id
        vimeo_id

        id
        first_name
        last_name
        slug
        picture
        images
        companies {
          id
          name
          picture
          description
          images
          picture
        }
        meals {
          entity_id
          batch_id
          name
          sku
          image
          image_path
          calories
          category_id
          stock
          chef_firstname
          chef_lastname
          reviews
          rating
          stars
          feature {
            name
            description
            icon
            color
            background
          }
          short_description
          is_premium
        }
        landing_page_url
      }
    }
  `,
    { id: +chef_id }
  )

/**
 * Get reviews by chef_id
 * @param {string} chef_id
 * Get Meal Data
 */
export const getReviewsByChefId = ({ chef_id }) =>
  gqlClient.publicQuery(
    `
  query chefReviews($id: Int! ) {
    chefReviews(id: $id) {
      reviews {
        entity_id
        review_id
        status_id
        title
        detail
        nickname
        customer_id
        customer_name
        rating
        stars
        product_name
        product_image
        created_at
      }
      reviews_count
    }
  }
`,
    { id: +chef_id }
  )

export const updateOrderComment = ({ id, comment }) =>
  gqlClient.mutation(
    `
    mutation updateOrderComment($id: Int, $comment: String!){
      updateOrderComment(id: $id, comment: $comment) {
          ... on GenericResponse {
              result
          }
          ... on GenericError {
              error
          }
      }
    }
  `,
    {
      id,
      comment
    }
  )

export const getUserAllOrders = ({ user }) =>
  gqlClient.query(
    `
    query allOrders($user: Int) {
      allOrders(user: $user) {
        id
        deliveryDate
      }
    }
  `,
    {
      user: +user
    }
  )

export const getUserReferrals = () =>
  gqlClient.query(
    `
      query userReferrals {
          userReferrals {
              history {
                  name
                  createdAt
                  joinedIn
                  email
              }
              stats {
                  count
                  earned
              }
          }
      }
  `,
    {}
  )

export const getUserCurrentCredit = () =>
  gqlClient.query(`
    query {
      users {
        currentCredit
      }
    }
  `)

export const syncData = () =>
  gqlClient.query(`
    query syncData {
      syncData {
        success
      }
    }
  `)

export const getPromotions = async userid => {
  const auth0Token = await getTokenSilently()
  if (userid) {
    const response = await axios({
      headers: {
        Authorization: auth0Token ? `${auth0Token}` : ''
      },
      method: 'get',
      url: `${process.env.REACT_APP_SUBSCRIPTION_API_URL}/promotions/getPromotionAndCreditByUser?userid=${userid}`
    })
    return response.data
  }
  return
}

export const getRecipe = id =>
  menuService.publicQuery(getRecipeQuery, {
    id
  })

export const getCrossSelling = ({ date, cartItems }) =>
  menuService.query(
    getCrossSellingItems,
    {
      date,
      cartItems
    },
    true
  )

export const getMealsLiteDetail = id =>
  menuService.query(
    getMealsLite,
    {
      id
    },
    true
  )

export const getMealNutritionInfo = id =>
  menuService.query(
    getMealNutritionInfoLite,
    {
      id
    },
    true
  )

export const getIdleDeliveryDays = () =>
  gqlClient.query(
    `
      query IdleDeliveryDay {
        idleDeliveryDays {
            days
          }
      }
  `,
    {}
  )

export const creatAutopilotPromotion = ({ generalCoupon, origin }) =>
  gqlClient.mutation(
    `mutation ApplyAutopilotPromotionMutation($generalCoupon: String, $origin: String) {
          applyAutopilotPromotionMutation(generalCoupon: $generalCoupon, origin: $origin) {
            ... on ApplyPromotionErrorType {
              error
            }
            ... on ApplyPromotionType {
              promotion
            }
          }
        }
      `,
    { generalCoupon, origin }
  )
